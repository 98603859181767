.teams {
  @extend %section-padding;
  &-wrap {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 1.5rem;
  }
  &-card {
    border: 1px solid var(--clr-border);
    @include transition(all 0.5s linear);
    figure {
      height: 120px;
      width: 120px;
      border-radius: 100%;
      margin: auto;
      overflow: hidden;
      position: relative;
      z-index: 1;
      img {
        object-fit: cover;
        border-radius: 100%;
        height: 100%;
        width: 100%;
        @include transition(transform 0.3s ease-in);
        @include backface(hidden);
      }
    }
    &:hover {
      box-shadow: 0 10px 30px rgba(30, 34, 40, 0.2);
      figure {
        img {
          @include transform(scale(1.2));
        }
      }
    }
    .is-square_icon {
      &.social-icon {
        box-shadow: none;
        &:hover {
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        }
      }
    }
  }
}
.modal {
  .teams-card {
    border: none;
    &:hover {
      box-shadow: none;
    }
  }
}
