.btn {
  -webkit-appearance: none;
  border: none;
  outline: none;
  padding: 0.8rem 1.5rem;
  cursor: pointer;
  border-radius: 1.25rem;
  display: inline-flex;
  background-color: transparent;
  @include transition(background-color .4s ease-in-out);
  &-primary {
    color: var(--clr-white);
    border-radius: 100px;
    transition: all 300ms ease-out;
    background-color: var(--clr-primary);
    &:focus,
    &:hover {
      background-color: var(--clr-primary-light);
    }
  }
  &-secondary{
    border:1px solid var(--clr-primary);
    border-radius: 100px;
    &:hover{
      color: var(--clr-primary);
    }
  }
  &-sm {
    padding: 0.7rem 1rem;
    border-radius: 1.25rem;
    font-size: var(--fs-md);
  }
  
  &-white {
    background-color: var(--clr-white);
    &:hover{
      background-color: var(--clr-blue-light);
    }
  }
  &-link{
    text-decoration: underline;
    padding:0;
    font-weight: 500;
    &:hover{
      color:var(--clr-blue-600)
    }
  }
  &:disabled,
  &-disabled {
    cursor: not-allowed;
  }
}
