.site-footer{
  background-color: var(--clr-bg);
  padding:8rem 0 5rem;
  @extend %shape;
  color:var(--clr-body-300);
  font-size: .875rem;
  &_wrap{
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(250px, 1fr));
    column-gap: 1.5rem;
  }
  .link-list{
    li{
      a{
        padding:.25rem 0;
        display: block;

        @include transition(all .4s ease-in-out);
        &:hover{
          color:var(--clr-primary);
          text-decoration: underline;
        }
      }
    }
  }
  .copyright{
    border-top:1px solid var(--clr-border)
  }
  .social-icons {
    svg {
      &:hover,
      &:focus {
        path {
          fill: var(--clr-white);
        }
      }
    }
  }
}
.scroll-top{
  position: fixed;
  bottom:3rem;
  right: 2rem;
  z-index: 9999;
  border-color:var(--clr-secondary);
  &:hover{
    svg{
      fill:var(--clr-secondary)
    }
  }
}

.site-logo {
  img {
    width: 100px;
  }
}
