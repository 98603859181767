.title {
  h5 {
    position: relative;
    margin-bottom: 1rem;
    span {
      padding-bottom: 10px;
    }
  }

  &-content {
    max-width: 768px;
    &_max {
      margin: auto;
    }
  }
}
.hero {
  background: linear-gradient(
    160deg,
    lighten($primary, 30%) 19%,
    lighten($primary, 10%) 70%
  );
  min-height: calc(100vh - 50px);
  padding-top: 200px;
  @extend %section-padding;
  position: relative;
  &::before {
    content: "";
    width: 100%;
    height: 50px;
    left: 0;
    position: absolute;
    background-image: url("../../images/curve.png");
    bottom: 0;
    transform: rotate(180deg);
  }
  &-content {
    --minwidth: 280px;
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(auto-fit, minmax(var(--minwidth), 1fr));
    align-items: center;
    @include breakpoint-down("md-tab") {
      text-align: center;
    }
    @include breakpoint-up("xl-phone") {
      --minwidth: 400px;
    }
    h5 {
      font-size: 1rem;
    }
    .btn {
      &-secondary {
        border-color: var(--clr-white);
        &:hover {
          background-color: var(--clr-white);
          color: var(--clr-body);
        }
      }
    }
  }
  &-figure {
    margin-bottom: 0;
    .circle {
      max-width: 280px;
      width: 100%;
      height: 280px;
      border-radius: 100vh;
      background-color: var(--clr-white);
      box-shadow: 0px 0 46px 0 rgba(255, 255, 255, 0.1);
      margin: auto;
      position: relative;
      padding: 1rem;
      .hero-image {
        object-fit: cover;
        height: 100%;
        width: 100%;
        border-radius: 100vh;
      }
      @include breakpoint-up("xl-phone") {
        max-width: 400px;
        width: 100%;
        height: 400px;
      }
    }
    .hero-svg {
      position: absolute;
      z-index: 2;
      display: block;
      max-width: 70px;
      border-radius: 50%;
      max-height: 70px;
      .hexagon {
        max-width: 70px;
        height: 70px;
        fill: var(--clr-white);
        filter: drop-shadow(0px 0px 29px rgba(0, 0, 0, 0.08));
      }
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        @include transform(translate(-50%, -50%));
        z-index: 3;
        max-width: 60px;
      }
      &.top-left {
        left: 0;
        top: 2rem;
      }
      &.top-right {
        right: 0;
        top: 2rem;
      }
      &.bottom-left {
        left: 0;
        bottom: 3rem;
      }
      &.bottom-right {
        right: 0;
        bottom: 3rem;
      }
      &.bottom {
        bottom: -5vh;
        left: 40%;
      }
      @include breakpoint-up("xl-phone") {
        max-width: 100px;
        max-height: 100px;
        .hexagon {
          max-width: 100px;
          height: 100px;
        }
      }
    }
    @include breakpoint-up("lg-tab") {
      margin-bottom: 3rem;
    }
  }
}
.about {
  @extend %section-padding;

  position: relative;
  &-content {
    max-width: 960px;
    margin: auto;
  }
}
.services {
  @extend %section-padding;
  overflow: hidden;
  &-wrap {
    display: grid;
    --min-width: 280px;
    grid-template-columns: repeat(auto-fill, minmax(var(--min-width), 1fr));
    @include breakpoint-up("xl-phone") {
      --min-width: 320px;
    }
  }
  &-item {
    @include transition(all 0.5s linear);
    &:hover {
      box-shadow: 0 10px 30px rgba(30, 34, 40, 0.2);
    }
  }
  &-page {
    .services-item {
      border: 1px solid var(--clr-border);
    }
  }
}
.highlight {
  @extend %section-padding;
  &-wrap {
    display: grid;
    --minwidth: 280px;
    grid-template-columns: repeat(auto-fit, minmax(var(--minwidth), 1fr));
    @include breakpoint-up("md-tab") {
      --minwidth: 450px;
    }
    @include breakpoint-up("sm-desktop") {
      --minwidth: 550px;
    }
  }
  &-item {
    border: 1px solid var(--clr-border);
    @include transition(all 0.4s ease-in-out);
    cursor: pointer;
    &_figure {
      margin-bottom: 1.5rem;
      overflow: hidden;
      border-radius: 8px;
      max-height: 350px;
      overflow: hidden;
      img {
        display: block;
        object-fit: contain;
        border-radius: 8px;
        @include transition(all 0.3s ease-in-out);
      }
    }
    &_content {
      @include breakpoint-up("md-tab") {
        padding: 0 1rem;
      }
    }
    &_client {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    }
    &:hover {
      box-shadow: 0 10px 30px rgba(30, 34, 40, 0.1);
      .highlight-item_figure {
        img {
          @include transform(scale(1.2));
        }
      }
    }
  }
}
.trusted {
  padding-bottom: 8rem;
  .swiper-wrapper {
    padding: 1rem 0;
  }
  .swiper-slide {
    height: auto;
    padding: 1rem;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  &-card {
    img {
      object-fit: contain;
      max-width: 100%;
      max-height: 100px;
    }
  }
}
