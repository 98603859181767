input,textarea{
  outline: none;
  box-shadow: none;
  border:none;
  height: calc(2em + 0.75rem + 2px);
  padding: 0.375rem 1rem;
  background-clip: padding-box;
  border:1px solid var(--clr-border);
  width: 100%;
  border-radius: 8px;
  
  @include input-placeholder {
    text-transform: capitalize;
    font-size: .875rem;
  }
}
textarea{
  height: 100px
}
.is-error{
  color:var(--clr-secondary);
  &::first-letter{
    text-transform: capitalize;
  }
}
.is-success{
  color:var(--clr-primary);
  text-align: center;
  &::first-letter{
    text-transform: capitalize;
  }
}