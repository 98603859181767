//Toothpillow color setting
$balck-900: #000000;
$black-700: #323232;
$black-300:#7f7f7f;
$black-500:#666666;
$primary:#1E6C60;
$primary-light:#34A853;
$secondary:#F55446;
$blue: #347EF6;
$orange:#E77A11;
$purple:#41085E;
$purple-light: #d7bcd7;
$pink: #eaadca;
$yellow: #FDBE67;
$white: #fff;
$bg:#F6F7F9;
$border:#f0f0f0;
$brown: #BF4A5C;

:root {
  --clr-body: #{$black-700};
  --clr-body-500:#{$black-500};
  --clr-body-300:#{$black-300};
  --clr-primary: #{$primary};
  --clr-primary-light:#{$primary-light};
  --clr-secondary:#{$secondary};
  --clr-blue: #{$blue};
  --clr-purple:#{$purple};
  --clr-pink: #{$pink};
  --clr-orange: #{$orange};
  --clr-yellow: #{$yellow};
  --clr-white: #{$white};
  --clr-brown: #{$brown};
  --clr-bg:#{$bg};
  --clr-border:#{$border};
  --transition-speed: 0.3s
}

//for dark and light theme

// :root[data-theme="light"] {
// }

// :root[data-theme="light"] {
// }
