.card{
  &-video{
    figure{
      overflow: hidden;
      position: relative;
      img{
        object-fit: contain;
      }
      video{
        width: 100%;
        height: 100%;
      }
      .play-icon{
        position: absolute;
        top:50%;
        @include transform(translateY(-50%));
        z-index: 1;
        margin:auto;
        left:0;
        right:0;
        svg{
          max-width: 14px;
        }
      }
    }
  }
}