%menu-after {
  padding: 1.5rem 0.5rem;
  display: block;
  position: relative;
  &::after {
    content: "";
    width: 0;
    position: absolute;
    @include transition(width 0.4s ease-in-out);
    bottom: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    height: 4px;
    background-color: lighten($secondary,30%);
    border-radius: 50%;
  }
}
%menu-after_active {
  &::after {
    width: 100%;
    right: 0;
    opacity: 1;
    visibility: visible;
    display: block;
  }
}
nav {
  position: fixed;
  right:-100%;
  opacity: 0;
  bottom:0;
  height:100vh;
  visibility: visible;
  
  @include transform(translateX(-100%));
  @include transition(all .5s ease-in-out);
  @include breakpoint-up('lg-tab'){
    opacity: 1;
    visibility: visible;
    transform: none;
    position: relative;
    height: auto;
    right: unset;
    bottom: unset;
    left:unset
  }
  ul {
    column-gap: 2rem;
    flex-flow: column wrap;
    @include breakpoint-up('lg-tab'){
      flex-flow: row nowrap;
    }
    li {
      a {
        transition: color 0.5s;
        @extend %menu-after;
        @include breakpoint-up("lg-tab") {
          padding: 1rem 0;
        }
      }
      &.is-active,&:hover {
        a {
          color: var(--clr-secondary);
          &::after {
            @extend %menu-after_active

          }
        }
      }
    }
  }
}
