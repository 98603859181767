/* Box sizing rules */

*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
/* by default, ScrollTrigger sets overflow to auto so that the scrollbar is factored in particularly when pinning so that the pinned elements' sizes are correct, but with LocomotiveScroll we want to force that to be hidden because it does its own [fake] scrollbar */
.smooth-scroll {
  overflow: hidden !important;
}

/* Remove default margin */

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
  padding: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */

ul[role="list"],
ol[role="list"],
li {
  list-style: none;
}

dl,
ol,
ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

/* Set core root defaults */

html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */

body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.3;
  overflow-x: hidden;
}

/* A elements that don't have a class get default styles */

a:not([class]) {
  text-decoration-skip-ink: auto;
}
a{
  text-decoration: none;
  color:inherit;
}

/* Make images easier to work with */

img,
picture {
  max-width: 100%;
  display: block;
  height: auto;
}

/* Inherit fonts for inputs and buttons */

input,
button,
textarea,
select {
  font: inherit;
  -webkit-appearance: none;
  border-radius: 0;
}

table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */

@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
