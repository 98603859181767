@import "menu";
%header-container {
  @include breakpoint-up("xl-phone") {
    max-width: 540px;
  }
  @include breakpoint-up("md-tab") {
    max-width: 720px;
  }

  @include breakpoint-up("lg-tab") {
    max-width: 980px;
  }

  @include breakpoint-up("sm-desktop") {
    max-width: 1180px;
  }
  @include breakpoint-up("md-desktop") {
    max-width: 1190px;
  }
}
.site-header {
  z-index: 99;
  position: absolute;
  left: 0;
  right: 0;
  top: 2rem;
  transition: all 0.5s ease-in-out;
  margin: auto;
  @extend %header-container;
  @include breakpoint-down("xl-tab") {
    padding: 0 1.5rem;
  }
  &_wrap {
    background-color: var(--clr-white);
    padding: 1rem 2rem;
    box-shadow: 0 0.25rem 0.75rem rgba(30, 34, 40, 0.2);
    border-radius: 0.4rem;
    margin: auto;
    @extend %header-container;
  }

  .btn {
    @include breakpoint-down("md-phone") {
      display: none;
    }
  }
  &.is-sticky {
    position: fixed;
    top: 0;
    background-color: var(--clr-white);
    max-width: 100%;
    border-radius: 0;
    box-shadow: 0 0.25rem 0.75rem rgba(30, 34, 40, 0.2);
    .site-header_wrap {
      box-shadow: none;
      background-color: transparent;
    }
  }
  &.menu-active {
    nav {
      opacity: 1;
      visibility: visible;
      @include transform(translateX(0));
      left: 0;
      right: auto;
      width: 70%;
      background-color: var(--clr-white);
      @include scrollbar(var(--clr-bg), var(--clr-body), var(--clr-border));
      z-index: 99999;
      ul {
        padding: 1rem;
      }
    }
  }
  .menu-toggle {
    @include breakpoint-up("lg-tab") {
      display: none;
    }
    &_open {
      position: fixed;
      top: 2rem;
      right: 2rem;
    }
  }
}
