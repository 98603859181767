.modal {
  position: fixed;
  top: 0;
  left: 0;
  @include flexbox();
  @include justify-content(center);
  @include align-items(center);
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  z-index: 7;
  opacity: 0;
  visibility: hidden;
  @include transition(all 0.1s ease-in-out);
  padding:1rem;
  &-cntr {
    min-width: 280px;
    max-width: 100%;
    max-height: 95vh;
    @include flexbox();
    @include flex-direction(column);
    background-color: var(--clr-white);
    flex-grow: 0;
    flex-shrink: 0;
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
      0px 24px 38px 3px rgba(0, 0, 0, 0.14),
      0px 9px 46px 8px rgba(0, 0, 0, 0.12);
    position: relative;
    @include transform(scale(0.9));
    @include transition(all 0.3s ease-in-out);
    @include breakpoint-up("md-phone") {
      max-width: 98vw;
      width: 98vw;
    }
    @include breakpoint-up("md-tab") {
      max-width: 576px;
    }
    &_radius {
      border-radius: 30px;
    }
    &_sm {
      @include breakpoint-up("md-tab") {
        max-width: 480px;
      }
    }
    &_lg {
      @include breakpoint-up("xl-tab") {
        max-width: 768px;
      }
      @include breakpoint-up("md-desktop") {
        max-width: 800px;
      }
    }
    &_xl {
      @include breakpoint-up("md-tab") {
        max-width: 95vw;
      }
    }
    &_xxl {
      @include breakpoint-up("md-phone") {
        max-width: 100vw;
        width: 100vw;
        min-height: 100vh;
        max-height: 100vh;
      }
    }
  }
  &-header {
    flex-shrink: 0;
    padding: 2rem 2rem 0 2rem;
    padding-top: 2rem;
    &.is-border {
      border-bottom: 1px solid var(--clr-body);
    }
  }
  &-footer {
    flex-shrink: 0;
    padding: 0 2rem 2rem 2rem;
    &.is-border {
      border-top: 1px solid var(--clr-body-body);
    }
  }
  &-body {
    padding: 2rem;
    @include flex-grow(1);
    @include scrollbar(
      var(--clr-bg),
      var(--clr-body),
      var(--clr-body)
    );
  }
  &-close {
    position: absolute;
    right: 1.5rem;
    top: 1rem;

    cursor: pointer;
    svg {
      &:hover {
        // color:var(--clr-primary-500)
      }
    }

    &.is-border {
      // border: 1px solid var(--clr-body-400);
    }
  }
  &-show {
    z-index: 111112;
    opacity: 1;
    visibility: visible;
    .modal-cntr {
      @include transform(scale(1));
      filter: alpha(opacity=100);
    }
  }
}

.warning-content {
  @include flex-direction(column);
  padding: 3rem 3rem 1rem 3rem;
  text-align: center;

  h4 {
    text-transform: capitalize;
    margin-bottom: 0.5rem;
  }

  .warning-icon {
    // color: var(--secondary-color);
    margin-bottom: 1rem;

    i {
      font-size: 3rem;
    }
  }
}
