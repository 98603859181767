@mixin radius($value) {
  -webkit-border-radius: $value;
  -moz-border-radius: $value;
  -ms-border-radius: $value;
  border-radius: $value;
}

@mixin radius-mix($value...) {
  -webkit-border-radius: $value;
  -moz-border-radius: $value;
  -ms-border-radius: $value;
  border-radius: $value;
}
